import {catch5xx, catch404, getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IPropertyGallery} from "../../property/interfaces/IPropertyDetail";
import {IOfferGallery} from "../detail/types/IOfferGallery";

export const fetchOfferGalleryPictures = (offerId: number): Promise<IOfferGallery> => {
    const url = apiV2Link.offer.pictures(Scenario.OFFER_DETAIL_GALLERY, {offerId});

    return getRequest({}, url)
        .then((result: {gallery: IOfferGallery}) => {
            return Promise.resolve(result.gallery);
        })
        .catch(
            catch5xx((err) => {
                notifyBugsnag({message: `Offer gallery pictures fetch`}, `Offer gallery pictures fetching failed`, {originalError: err});
                return Promise.resolve([]);
            })
        )
        .catch(
            catch404(() => {
                return Promise.resolve([]);
            })
        );
};
export const fetchPropertyGalleryPictures = (propertyId: number): Promise<IPropertyGallery> => {
    const url = apiV2Link.property.pictures(Scenario.PROPERTY_DETAIL_GALLERY, {propertyId});

    return getRequest({}, url)
        .then((result: {gallery: IPropertyGallery}) => {
            return Promise.resolve(result.gallery);
        })
        .catch(
            catch5xx((err) => {
                notifyBugsnag({message: `Property gallery pictures fetch`}, `Property gallery pictures fetching failed`, {originalError: err});
                return Promise.resolve([]);
            })
        )
        .catch(
            catch404(() => {
                return Promise.resolve([]);
            })
        );
};
